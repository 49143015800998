import { RefObject, useEffect, useState } from 'react'

/*
 * Gets the dimensions of the video stream of a video element.
 * It returns an array of two numbers, the first being the width and the second being the height.
 */
export const useVideoDimensions = (
  videoRef: RefObject<HTMLVideoElement> | null,
): [number, number] => {
  const [videoDimensions, setVideoDimensions] = useState<[number, number]>([
    videoRef?.current?.videoWidth || 0,
    videoRef?.current?.videoHeight || 0,
  ])

  useEffect(() => {
    const video = videoRef?.current
    if (!video) return

    const updateWidth = () =>
      setVideoDimensions([video.videoWidth, video.videoHeight])

    // Observe changes using ResizeObserver
    const resizeObserver = new ResizeObserver(() => {
      updateWidth()
    })

    resizeObserver.observe(video)

    // Ensure we catch the initial video width when metadata is loaded
    video.addEventListener('loadedmetadata', updateWidth)
    video.addEventListener('resize', updateWidth)

    return () => {
      resizeObserver.disconnect()
      video.removeEventListener('loadedmetadata', updateWidth)
      video.removeEventListener('resize', updateWidth)
    }
  }, [videoRef])

  return videoDimensions
}
