import { RefObject, useEffect, useState } from 'react'

/*
 * Gets the dimensions of the video stream of a video element.
 * It returns an array of two numbers, the first being the width and the second being the height.
 */
export const useCanvasDimensions = (
  canvasRef: RefObject<HTMLCanvasElement> | null,
): [number, number] => {
  const [canvasDimensions, setCanvasDimensions] = useState<[number, number]>([
    canvasRef?.current?.width || 0,
    canvasRef?.current?.height || 0,
  ])

  useEffect(() => {
    const canvas = canvasRef?.current
    if (!canvas) return

    const updateWidth = () => {
      setCanvasDimensions([canvas.width, canvas.height])
    }

    // Observe changes using ResizeObserver
    const resizeObserver = new ResizeObserver(() => {
      updateWidth()
    })

    resizeObserver.observe(canvas)

    // Ensure we catch the initial video width when metadata is loaded
    canvas.addEventListener('loadedmetadata', updateWidth)
    canvas.addEventListener('resize', updateWidth)

    return () => {
      resizeObserver.disconnect()
      canvas.removeEventListener('loadedmetadata', updateWidth)
      canvas.removeEventListener('resize', updateWidth)
    }
  }, [canvasRef])

  return canvasDimensions
}
