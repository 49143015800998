import { RefObject, useEffect, useRef } from 'react'

export interface UseClonedCanvasProps {
  sourceElement: HTMLCanvasElement | HTMLVideoElement | null
  sourceWidth: number
  sourceHeight: number
  destinationWidth: number
  destinationHeight: number
}

export const useClonedCanvas = ({
  sourceElement,
  sourceWidth,
  sourceHeight,
  destinationWidth,
  destinationHeight,
}: UseClonedCanvasProps): RefObject<HTMLCanvasElement> => {
  const clonedCanvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (!clonedCanvasRef.current) return

    const clonedCanvas = clonedCanvasRef.current
    clonedCanvas.width = destinationWidth
    clonedCanvas.height = destinationHeight

    const ctx = clonedCanvas.getContext('2d', { alpha: false })
    if (!ctx) return

    let frame = 0

    const source = {
      x: 0,
      y: 0,
      width: sourceWidth,
      height: sourceHeight,
    }

    const destination = {
      x: 0,
      y: 0,
      width: destinationWidth,
      height: destinationHeight,
    }

    const sourceAspectRatio = source.width / Math.max(source.height, 1)
    const destinationAspectRatio =
      destination.width / Math.max(destination.height, 1)
    if (sourceAspectRatio > destinationAspectRatio) {
      // If the video is taller (16:10 inside a 16:9 box), there will be horizontal black bars
      const targetCanvasHeight = destinationWidth / sourceAspectRatio
      const offset = (destinationHeight - targetCanvasHeight) / 2
      destination.y = offset
      destination.height = destinationHeight - offset * 2
    } else {
      // If the video is wider (16:9 inside a 16:10 box), there will be vertical black bars
      const targetCanvasWidth = destinationHeight * sourceAspectRatio
      const offset = (destinationWidth - targetCanvasWidth) / 2
      destination.x = offset
      destination.width = targetCanvasWidth
    }

    const cloneCanvas = () => {
      if (!sourceElement) return

      ctx.drawImage(
        sourceElement,
        source.x,
        source.y,
        source.width,
        source.height,
        destination.x,
        destination.y,
        destination.width,
        destination.height,
      )
      frame = requestAnimationFrame(cloneCanvas)
    }

    frame = requestAnimationFrame(cloneCanvas)

    return () => {
      if (frame) {
        cancelAnimationFrame(frame)
      }
    }
  }, [
    sourceElement,
    sourceWidth,
    sourceHeight,
    destinationHeight,
    destinationWidth,
  ])

  return clonedCanvasRef
}
