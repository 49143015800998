export const getStreamBorderRadius = (width: number): string => {
  if (width > 400) {
    return '1rem'
  } else if (width > 200) {
    return '0.75rem'
  } else if (width > 150) {
    return '0.5rem'
  }
  return '0.25rem'
}
