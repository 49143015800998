import { Alert } from 'australis'
import { useTelehealthStore } from 'src/blocks/Zoom/hooks/useTelehealthStore'
import {
  AudioState,
  ZoomSessionState,
  ZoomUIZIndex,
} from 'src/blocks/Zoom/types'

const VideoStreamErrors: React.FC = () => {
  const [audioState, sessionState, sessionError] = useTelehealthStore(
    (state) => [state.audioState, state.sessionState, state.sessionError],
  )

  const errors = []

  if (sessionError) {
    errors.push(sessionError)
  }
  if (audioState === AudioState.Error) {
    errors.push(
      'Could not join audio. Please ensure you have a microphone connected.',
    )
  }
  if (sessionState === ZoomSessionState.Errored) {
    errors.push('Unable to connect due to an unknown error.')
  }
  if (sessionState === ZoomSessionState.Prevented) {
    errors.push('Prevented from starting a new Telehealth Session.')
  }

  if (errors.length === 0) return null

  return (
    <div
      style={{
        position: 'absolute',
        top: '1rem',
        left: '1rem',
        right: '1rem',
        zIndex: ZoomUIZIndex.VideoStreamErrors,
      }}
    >
      {errors.map((error) => (
        <Alert show status='error' key={error}>
          {error}
        </Alert>
      ))}
    </div>
  )
}

VideoStreamErrors.displayName = 'VideoStreamErrors'

export default VideoStreamErrors
