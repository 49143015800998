import { TelehealthLayoutType } from 'australis/dist/hooks/useTelehealthLayout/types'
import { useTelehealthStore } from 'src/blocks/Zoom/hooks/useTelehealthStore'
import { useBreakpoint } from 'src/hooks/useBreakpoint'

export const useLayoutConfig = (): {
  type: TelehealthLayoutType
  tilesCount: number
} => {
  const breakpoint = useBreakpoint()
  const isSmallScreen = ['xs', 'sm'].includes(breakpoint)

  const participantsCount = useTelehealthStore(
    (state) => state.participants.length,
  )
  const [shareScreenState] = useTelehealthStore((state) => [
    state.shareScreenState,
  ])

  const isShareScreenActive = shareScreenState.state === 'Active'
  const tilesCount = participantsCount + (isShareScreenActive ? 1 : 0)

  if (isSmallScreen) {
    return { type: 'grid', tilesCount }
  }

  if (isShareScreenActive) {
    return { type: 'sidebar', tilesCount }
  }

  if (tilesCount === 2) {
    return { type: 'spotlight', tilesCount }
  }

  return { type: 'grid', tilesCount }
}
