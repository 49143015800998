import { decodeJwtPayload } from '@upvio/jwt-decode/dist'
import { Flex, Grid, Text, useDraggable } from 'australis'
import { useMemo } from 'react'
import { createPortal } from 'react-dom'
import {
  useTelehealthParticipants,
  useTelehealthStore,
  useTelehealthClient,
  useTelehealthSession,
  useTelehealthStream,
} from 'src/blocks/Zoom/hooks/useTelehealthStore'
import { LogLevel, useLogger } from 'src/hooks/useLogger'
import { useTelehealthSettings } from '../hooks/useTelehealthSettings'

const DEBUG_PANEL_WIDTH = 168
const DEBUG_PANEL_HEIGHT = 220

const DebugPanel: React.FC = () => {
  const client = useTelehealthClient()
  const session = useTelehealthSession()
  const stream = useTelehealthStream()
  const participants = useTelehealthParticipants()

  const {
    sessionState,
    userId,
    audioState,
    selfVideoRef,
    renderSelfWithVideoElement,
  } = useTelehealthStore()

  const { cameras, microphones, speakers } = useTelehealthSettings()

  const logger = useLogger()
  const { tpc: topic } = useMemo(
    () => (session?.token ? decodeJwtPayload(session?.token) : {}),
    [session?.token],
  )

  const { draggableRef, draggableStyles, isDragging } = useDraggable({
    bounds: 'viewport',
    initialX: window.innerWidth - DEBUG_PANEL_WIDTH - 20,
    initialY: window.innerHeight - DEBUG_PANEL_HEIGHT - 20,
  })

  if (logger.level > LogLevel.Debug) return null

  const dotBg = 'white'
  const dotColor = 'var(--gray-light-300)'
  const dotSize = '2px'
  const dotSpace = '6px'

  const dotPatternStyle = {
    background: `
    linear-gradient(90deg, ${dotBg} calc(${dotSpace} - ${dotSize}), transparent 1%) center / ${dotSpace} ${dotSpace},
    linear-gradient(${dotBg} calc(${dotSpace} - ${dotSize}), transparent 1%) center / ${dotSpace} ${dotSpace},
    ${dotColor}
  `,
  }

  return createPortal(
    <Flex
      gap='1rem'
      direction='column'
      UNSAFE_style={{
        ...draggableStyles,
        userSelect: 'none',
        cursor: 'auto',
        resize: 'both',
        width: `${DEBUG_PANEL_WIDTH}px`,
        height: `${DEBUG_PANEL_HEIGHT}px`,
        overflow: 'hidden',
        padding: '0.75rem',
        backgroundColor: 'var(--white)',
        borderRadius: '0.5rem',
        border: '1px solid var(--gray-light-100)',
        boxShadow:
          'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
      }}
    >
      <div
        ref={draggableRef as React.RefObject<HTMLDivElement>}
        style={{
          height: '1.4rem',
          width: '100%',
          userSelect: isDragging ? 'none' : 'auto',
          cursor: isDragging ? 'grabbing' : 'grab',
          ...dotPatternStyle,
        }}
      />
      <Flex
        height='100%'
        width='100%'
        UNSAFE_style={{
          overflow: 'auto',
        }}
      >
        <Grid gap='0.25rem' columns='1fr'>
          <Text type='bold'>Client:</Text>
          <Text>{client ? 'connected' : 'disconnected'}</Text>

          <Text type='bold'>Session:</Text>
          <Text>{sessionState}</Text>

          <Text type='bold'>Topic:</Text>
          <Text lineClamp={1}>{topic && ` (${topic})`}</Text>

          <Text type='bold'>Stream:</Text>
          <Text>{stream ? 'active' : '-'}</Text>

          <Text type='bold'>Self Video:</Text>
          <Text>{selfVideoRef?.current ? 'available' : 'not mounted'}</Text>

          <Text type='bold'>Render Self With Video Element:</Text>
          <Text>{renderSelfWithVideoElement ? 'yes' : 'no'}</Text>

          <Text type='bold'>Audio:</Text>
          <Text>{audioState}</Text>

          <Text type='bold'>Participants:</Text>
          <Text>{participants.length}</Text>

          <Text type='bold'>UserId:</Text>
          <Text>{userId}</Text>

          <Text type='bold'>User Name:</Text>
          <Text>{client?.getCurrentUserInfo()?.displayName}</Text>

          <Text type='bold'>Cameras:</Text>
          <Text elementType='pre'>{JSON.stringify(cameras, null, 2)}</Text>

          <Text type='bold'>Microphones:</Text>
          <Text elementType='pre'>{JSON.stringify(microphones, null, 2)}</Text>

          <Text type='bold'>Speakers:</Text>
          <Text elementType='pre'>{JSON.stringify(speakers, null, 2)}</Text>
        </Grid>
      </Flex>
    </Flex>,
    document.body,
  )
}

DebugPanel.displayName = 'ZoomDebugPanel'

export default DebugPanel
