import { Return } from '@carbon/icons-react'
import { Button, Flex, useTelehealthLayout } from 'australis'
import dynamic from 'next/dynamic'
import { useCallback, useRef } from 'react'
import { createPortal } from 'react-dom'

import { ClonedScreenShareCanvas } from 'src/blocks/Zoom/components/video/streams/ClonedScreenShareCanvas'
import VideoStreamErrors from 'src/blocks/Zoom/components/VideoStreamErrors'
import { useLayoutConfig } from 'src/blocks/Zoom/hooks/useLayoutType'
import {
  useTelehealthParticipants,
  useTelehealthStore,
} from 'src/blocks/Zoom/hooks/useTelehealthStore'
import { useBusinessRouter } from 'src/hooks/useBusinessRouter'
import { ZoomSessionState } from '../../types'
import DebugPanel from '../DebugPanel'
import { getStreamBorderRadius } from '../video/overlays/utils'

const ParticipantVideoStream = dynamic(
  () =>
    import('src/blocks/Zoom/components/video/streams/ParticipantVideoStream'),
  { ssr: false },
)

const Controls = dynamic(
  () => import('src/blocks/Zoom/components/controls/Controls'),
  {
    ssr: false,
  },
)

const RecordingStatusIndicator = dynamic(
  () =>
    import(
      'src/blocks/Zoom/components/video/overlays/RecordingStatusIndicator'
    ),
  {
    ssr: false,
  },
)

const PANEL_WIDTH = 340
const PANEL_HEIGHT = 430

const VideoConferencePopover: React.FC = () => {
  const businessRouter = useBusinessRouter()
  const participants = useTelehealthParticipants()
  const containerRef = useRef<HTMLDivElement>(null)
  const { tilesCount } = useLayoutConfig()
  const tiles = useTelehealthLayout({
    tilesCount,
    type: 'grid',
    containerRef,
  })

  const [telehealthAppointmentId, sessionState, shareScreenState] =
    useTelehealthStore((state) => [
      state.appointmentId,
      state.sessionState,
      state.shareScreenState,
    ])

  const telehealthAppointmentUrl = `/appointments/${telehealthAppointmentId}/telehealth`
  const inMeetingRoom =
    telehealthAppointmentId &&
    businessRouter.asPath.includes(telehealthAppointmentUrl)

  const shouldDisplay =
    telehealthAppointmentId &&
    participants.length > 0 &&
    !inMeetingRoom &&
    sessionState === ZoomSessionState.Connected
      ? true
      : false

  const getTileStyle = useCallback(
    (index: number) => {
      if (!tiles[index]) return {}

      return {
        position: 'absolute' as const,
        transition: 'all 200ms ease-in-out',
        top: `${tiles[index]?.top}px`,
        left: `${tiles[index].left}px`,
        width: `${tiles[index].width}px`,
        height: `${tiles[index].height}px`,
        borderRadius: getStreamBorderRadius(tiles[index]?.width ?? 0),
      }
    },
    [tiles],
  )

  return createPortal(
    <Flex
      gap='0.5rem'
      direction='column'
      data-testid='video-conference-popover'
      UNSAFE_style={{
        position: 'fixed',
        bottom: '1rem',
        right: shouldDisplay ? '1rem' : `${-(PANEL_WIDTH + 20)}px`,
        transition: 'right 400ms ease-in-out',
        userSelect: 'none',
        cursor: 'auto',
        width: `${PANEL_WIDTH}px`,
        height: `${PANEL_HEIGHT}px`,
        overflow: 'hidden',
        padding: '0.5rem',
        backgroundColor: 'var(--white)',
        borderRadius: '1rem',
        border: '1px solid var(--gray-light-100)',
        boxShadow:
          'rgba(60, 64, 67, 0.15) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
        zIndex: 10000,
      }}
    >
      <Button
        size='xs'
        color='secondary'
        variant='outline'
        onPress={() => businessRouter.push(telehealthAppointmentUrl)}
      >
        <Return />
        Back to meeting
      </Button>
      <div
        data-test-id='zoom-meeting-stream-container'
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <Flex
          UNSAFE_style={{
            padding: '0.5rem',
            backgroundColor: 'var(--gray-light-050)',
            borderRadius: '1rem',
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            overflow: 'hidden',
          }}
        >
          <div
            data-test-id='meeting-room__participant-grid'
            ref={containerRef}
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              maxWidth: '100%',
              overflow: 'hidden',
              borderRadius: '1rem',
            }}
          >
            {shareScreenState.state === 'Active' && (
              <ClonedScreenShareCanvas
                width={tiles[0]?.width || 0}
                height={tiles[0]?.height || 0}
                style={getTileStyle(0)}
              />
            )}
            {tiles.length > 0 &&
              shouldDisplay &&
              participants.map((participant, index) => {
                const layoutIndex =
                  shareScreenState.state === 'Active' ? index + 1 : index
                return (
                  <ParticipantVideoStream
                    key={`participant-stream-popover-${participant.userId}`}
                    userId={participant.userId}
                    resolution={360}
                    dimensions={tiles[layoutIndex] ?? {}}
                    layoutProps={getTileStyle(layoutIndex)}
                  />
                )
              })}
          </div>
        </Flex>
        <VideoStreamErrors />
        <DebugPanel />
        <RecordingStatusIndicator />
      </div>
      <Controls />
    </Flex>,
    document.body,
  )
}

VideoConferencePopover.displayName = 'VideoConferencePopover'
export default VideoConferencePopover
