import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'
import type { MediaDevice } from '@zoom/videosdk'

export interface VideoSettings {
  mirrored: boolean
  hd: boolean
}

/**
 * Generic zustand setter to avoid having to type out the full setter type.
 */
type Setter<T> = (value: T) => void

interface TelehealthSettingsStore {
  // Media device lists
  cameras: MediaDevice[]
  microphones: MediaDevice[]
  speakers: MediaDevice[]

  // Active device selections
  activeCamera: string | null
  activeMicrophone: string | null
  activeSpeaker: string | null

  // Device state flags
  isPreviewCameraOn: boolean
  isPreviewMicMuted: boolean
  displaySettings: boolean

  // Actions for device lists
  setCameras: (cameras: MediaDevice[]) => void
  setMicrophones: (microphones: MediaDevice[]) => void
  setSpeakers: (speakers: MediaDevice[]) => void

  // Actions for active device selection
  setActiveCamera: (activeCamera: string | null) => void
  setActiveMicrophone: (activeMicrophone: string | null) => void
  setActiveSpeaker: (activeSpeaker: string | null) => void

  // Toggle actions
  setIsPreviewCameraOn: (isPreviewCameraOn: boolean) => void
  setIsPreviewMicMuted: (isPreviewMicMuted: boolean) => void

  toggleDisplaySettings: () => void
  setDisplaySettings: (displaySettings: boolean) => void

  // Video Settings
  videoSettings: VideoSettings
  setVideoSettings: Setter<Partial<VideoSettings>>
}

export const useTelehealthSettings =
  createWithEqualityFn<TelehealthSettingsStore>()(
    persist(
      devtools(
        (set) => ({
          // Initial state
          cameras: [],
          microphones: [],
          speakers: [],
          activeCamera: null,
          activeMicrophone: null,
          activeSpeaker: null,
          isPreviewCameraOn: true,
          isPreviewMicMuted: true,
          displaySettings: false,

          // Device list setters
          setCameras: (cameras: MediaDevice[]) => set({ cameras }),
          setMicrophones: (microphones: MediaDevice[]) => set({ microphones }),
          setSpeakers: (speakers: MediaDevice[]) => set({ speakers }),

          // Active device setters
          setActiveCamera: (activeCamera: string | null) =>
            set({ activeCamera }),
          setActiveMicrophone: (activeMicrophone: string | null) =>
            set({ activeMicrophone }),
          setActiveSpeaker: (activeSpeaker: string | null) =>
            set({ activeSpeaker }),

          // Toggle actions
          setIsPreviewCameraOn: (isPreviewCameraOn: boolean) =>
            set({ isPreviewCameraOn }),
          setIsPreviewMicMuted: (isPreviewMicMuted: boolean) =>
            set({ isPreviewMicMuted }),

          toggleDisplaySettings: () =>
            set((state) => ({
              displaySettings: !state.displaySettings,
            })),
          setDisplaySettings: (displaySettings: boolean) =>
            set({ displaySettings }),

          // Video Settings
          videoSettings: {
            hd: true,
            mirrored: true,
          },
          setVideoSettings: (videoSettings) =>
            set((state) => ({
              ...state,
              videoSettings: {
                ...state.videoSettings,
                ...videoSettings,
              },
            })),
        }),
        {
          enabled: process.env.NODE_ENV === 'development',
          name: 'TelehealthSettings',
        },
      ),
      {
        name: 'telehealth-settings',
        storage: createJSONStorage(() => localStorage),
        partialize: (state) => ({
          activeCamera: state.activeCamera,
          activeMicrophone: state.activeMicrophone,
          activeSpeaker: state.activeSpeaker,
        }),
      },
    ),
    shallow,
  )
