import { CSSProperties } from 'react'
import { useCanvasDimensions } from 'src/blocks/Zoom/hooks/useCanvasDimensions'
import { useClonedCanvas } from 'src/blocks/Zoom/hooks/useClonedCanvas'
import {
  useTelehealthSelfParticipant,
  useTelehealthStore,
  useTelehealthStream,
} from 'src/blocks/Zoom/hooks/useTelehealthStore'
import { useVideoDimensions } from 'src/blocks/Zoom/hooks/useVideoDimensions'

export const ClonedScreenShareCanvas = ({
  width,
  height,
  style,
}: {
  height: number
  width: number
  style?: CSSProperties
}) => {
  const stream = useTelehealthStream()
  const selfParticipant = useTelehealthSelfParticipant()
  const { shareScreenState, screenShareCanvasRef, screenShareVideoRef } =
    useTelehealthStore((state) => ({
      shareScreenState: state.shareScreenState,
      screenShareCanvasRef: state.screenShareCanvasRef,
      screenShareVideoRef: state.screenShareVideoRef,
    }))

  const shouldStreamSelfScreenShareInVideo =
    stream?.isStartShareScreenWithVideoElement()
  const isSelfScreenSharing =
    shareScreenState.state === 'Active' &&
    shareScreenState.userId === selfParticipant?.userId
  const isVideo = isSelfScreenSharing && shouldStreamSelfScreenShareInVideo
  const ref = isVideo ? screenShareVideoRef : screenShareCanvasRef

  const videoDimensions = useVideoDimensions(screenShareVideoRef)
  const canvasDimensions = useCanvasDimensions(screenShareCanvasRef)
  const [sourceWidth, sourceHeight]: [number, number] = isVideo
    ? videoDimensions
    : canvasDimensions

  const clonedCanvasRef = useClonedCanvas({
    sourceElement: ref?.current || null,
    sourceWidth,
    sourceHeight,
    destinationHeight: height,
    destinationWidth: width,
  })

  return (
    <canvas
      data-test-id='cloned-screenshare-canvas'
      ref={clonedCanvasRef}
      style={{
        ...style,
        backgroundColor: 'black',
      }}
    />
  )
}
